import { action, extendObservable, observable } from "mobx";

const userStateFactory = (restApi) => {
  const state = observable({
    auth: false,
    name: undefined,
    role: undefined,
    isAdmin: false,
    isGala: false,
    isFivestar: false,
    isCustomer: true,
    email: undefined,
    showtutorial: undefined,
    loginPageUrl: undefined,
    customer_id: undefined,
    surcharges: undefined,
    zip: undefined,
    city: undefined,
    country: undefined,
    street: undefined,
    street_number: undefined,
  });

  extendObservable(state, {
    fetched: false,
    fetch: async () => {
      try {
        const response = await restApi.getUserInfo();

        if (response == null) {
          throw new Error("response is null");
        }

        const {
          username,
          showtutorial,
          loginPageUrl,
          auth,
          email,
          customer_id,
          surcharges,
          customer_group,
          address,
          role,
        } = response;

        const group = customer_group?.name;

        action(() => {
          state.name = username;
          state.role = role;
          state.isAdmin = role === "admin";
          state.isGala = ["gala", "b2b handel"].includes(group?.toLowerCase());
          state.isFivestar = "fivestar-kunde" === group?.toLowerCase();
          state.isCustomer = !state.isAdmin && !state.isGala;
          state.showtutorial = showtutorial;
          state.loginPageUrl = loginPageUrl;
          state.auth = auth;
          state.email = email;
          state.customer_id = customer_id;
          state.surcharges = {
            productMarkup: surcharges?.surcharge_material,
            earthworks: surcharges?.surcharge_earthworks,
            equipment: surcharges?.surcharge_equipment,
            briefing: surcharges?.surcharge_briefing,
            documentation: surcharges?.surcharge_project_documentation,
          };
          state.zip = address?.zip;
          state.city = address?.city;
          state.country = address?.country;
          state.street = address?.street;
          state.street_number = address?.street_number;
        })();
      } catch (e) {
        console.error("fetch userinfo failed", e);
        action(() => {
          state.auth = false;
        })();
      } finally {
        action(() => {
          state.fetched = true;
        })();
      }
    },
  });

  return state;
};

export default userStateFactory;
