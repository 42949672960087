import React from "react";
import { useAutoFocus } from "../../hooks/useAutoFocus";
import WindowPopup from "./WindowPopup";
import ButtonWithRef from "../elements/Button";
import Radio from "../elements/Radio";

import {
  IRRIGATION_TUBE_TYPE,
  PRESSURE_TUBE_TYPE,
} from "../../config/dictionary";
import { action } from "mobx";
import { useIntl } from "react-intl";
import { observer } from "mobx-react";
import useStores from "../../hooks/useStores";
import { FinishPlanningDialogHeader } from "./FinishPlanningDialogHeader";

const TubeType = observer(() => {
  const { formatMessage } = useIntl();
  const { uiState } = useStores();
  const { confirmCallback, plan } = uiState;

  const ref = React.useRef();
  useAutoFocus(ref);

  return (
    <WindowPopup
      className="window-dialog tube-type"
      title={formatMessage({ id: "texts.steps.pipeline.calculationTitle" })}
    >
      <form>
        <div className="type-list">
          <div className="type-list-title">
            {formatMessage({ id: "texts.steps.pipeline.type.title" })}
          </div>
          <div className="type-item">
            <Radio
              value={
                plan.irrigationTubeType === IRRIGATION_TUBE_TYPE.BluLock24mm &&
                plan.pressureTubeType === PRESSURE_TUBE_TYPE.PE_25mm
              }
              onChange={action(() => {
                plan.irrigationTubeType = IRRIGATION_TUBE_TYPE.BluLock24mm;
                plan.pressureTubeType = PRESSURE_TUBE_TYPE.PE_25mm;
                uiState.savePlan();
              })}
              label={formatMessage({
                id: "texts.steps.pipeline.type.blulock",
              })}
            />
          </div>
          <div className="type-item">
            <div />
            <div>
              <b>
                {formatMessage({ id: "texts.steps.pipeline.type.peTitle" })}
              </b>
            </div>
          </div>
          <div className="type-item">
            <Radio
              value={
                plan.irrigationTubeType === IRRIGATION_TUBE_TYPE.PE_25mm &&
                plan.pressureTubeType === PRESSURE_TUBE_TYPE.PE_25mm
              }
              onChange={action(() => {
                plan.irrigationTubeType = IRRIGATION_TUBE_TYPE.PE_25mm;
                plan.pressureTubeType = PRESSURE_TUBE_TYPE.PE_25mm;
                uiState.savePlan();
              })}
              label={formatMessage({ id: "texts.steps.pipeline.type.pe25mm" })}
            />
          </div>
          <div className="type-item">
            <Radio
              value={
                plan.irrigationTubeType === IRRIGATION_TUBE_TYPE.PE_32mm &&
                plan.pressureTubeType === PRESSURE_TUBE_TYPE.PE_32mm
              }
              onChange={action(() => {
                plan.irrigationTubeType = IRRIGATION_TUBE_TYPE.PE_32mm;
                plan.pressureTubeType = PRESSURE_TUBE_TYPE.PE_32mm;
                uiState.savePlan();
              })}
              label={formatMessage({ id: "texts.steps.pipeline.type.pe32mm" })}
            />
          </div>
        </div>

        <div className="dialog-text">
          <div className="dialog-message">
            {formatMessage({
              id: "texts.steps.pipeline.calculationNeededConfirm.question",
            })}
          </div>
        </div>

        <div className="dialog-buttons">
          <ButtonWithRef
            title={formatMessage({
              id: "texts.steps.pipeline.calculationNeededConfirm.no",
            })}
            onClick={() => confirmCallback(false)}
            className="cancel"
          />

          <ButtonWithRef
            title={formatMessage({
              id: "texts.steps.pipeline.calculationNeededConfirm.yes",
            })}
            onClick={() => confirmCallback(true)}
            className="ok"
            ref={ref}
          />
        </div>
      </form>

      <FinishPlanningDialogHeader
        formatMessage={formatMessage}
        confirmCallback={confirmCallback}
        stepName={uiState.step.name}
        planToCheck={uiState.planToCheck}
      />
    </WindowPopup>
  );
});

export default TubeType;
