import * as React from "react";
import { useIntl } from "react-intl";
import WindowPopup from "../popups/WindowPopup";
import Button from "../elements/Button";
import { calculateTotalPrice, normalizeBomItems } from "../../utils/bomUtils";
import { observer } from "mobx-react";
import { priceFormat } from "@dvsproj/ipat-core/formatter";
import bomItemFactory from "../../store/types/bomItemFactory";
import useStores from "../../hooks/useStores";
import CouponSelection from "./CouponSelection";
import { redirectTo } from "../elements/Link";
import { COUPON_TYPES } from "../../config/dictionary";

const textsByType = {
  eco: {
    titleId: `texts.steps.bomType.eco.title`,
    subtitleId: undefined,
    descriptionId: `texts.steps.bomType.eco.description`,
    advantagesId: `texts.steps.bomType.eco.advantages`,
    buttonTextId: `texts.steps.bomType.eco.button`,
  },
  expert: {
    titleId: `texts.steps.bomType.expert.title`,
    subtitleId: `texts.steps.bomType.expert.subtitle`,
    descriptionId: `texts.steps.bomType.expert.description`,
    advantagesId: `texts.steps.bomType.expert.advantages`,
    buttonTextId: `texts.steps.bomType.expert.button`,
  },
  premium: {
    titleId: `texts.steps.bomType.premium.title`,
    subtitleId: undefined,
    descriptionId: `texts.steps.bomType.premium.description`,
    advantagesId: `texts.steps.bomType.premium.advantages`,
    buttonTextId: `texts.steps.bomType.premium.button`,
  },
};

const BomTypeItem = ({
  className,
  title,
  subtitle,
  price,
  description,
  advantages,
  buttonText,
  onClick,
  blurred,
}) => {
  const classNames = ["item"];
  if (className) classNames.push(className);
  if (blurred) classNames.push("item-blurred");
  return (
    <div className={classNames.join(" ")}>
      <div className="header">
        <div className="title">{title}</div>
        {subtitle && <div className="subtitle">{subtitle}</div>}
      </div>
      <div className="content">
        <div className="price-wrapper">
          <div className="price">{price}</div>
          <div className="description">{description}</div>
        </div>

        <div className="advantages">
          <ul>
            {advantages.map((text) => (
              <li key={text}>{text}</li>
            ))}
          </ul>
        </div>

        <div className="button-wrapper">
          <Button type="button" title={buttonText} onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

function calculateTotalPriceByType(type, plan, settingsState) {
  const tempBomItems = normalizeBomItems(
    plan.bomItems.map((item) => item.toJSON),
    settingsState
  ).map((data) => bomItemFactory(data, plan.pricesDictionary));

  plan.applyBomType(type, tempBomItems);
  return calculateTotalPrice(tempBomItems);
}

let BomType = () => {
  const intl = useIntl();
  const { formatMessage } = useIntl();
  const { uiState } = useStores();

  const plan = uiState.plan;
  const settingsState = uiState.settingsState;

  const planCoupon = plan.couponType;
  const planBomType = plan.bomType;

  const isFivestar = uiState.user.isFivestar;
  const savePlan = uiState.savePlan;
  const changeCouponType = plan.changeCouponType;
  React.useEffect(() => {
    if (!planCoupon && isFivestar) {
      changeCouponType(COUPON_TYPES.Fivestar);
      savePlan();
    }
  }, [planCoupon, isFivestar, changeCouponType, savePlan]);

  return (
    <WindowPopup
      wrapperClassName="recom-wrapper"
      title={formatMessage({ id: "texts.steps.bomType.title" })}
      className="bom-type"
    >
      <div className="bom-type-list">
        {Object.entries(textsByType).map(([type, el]) => (
          <BomTypeItem
            key={type}
            className={type}
            title={formatMessage({ id: el.titleId })}
            subtitle={
              el.subtitleId ? formatMessage({ id: el.subtitleId }) : undefined
            }
            price={formatMessage(
              { id: "texts.steps.bomType.price" },
              {
                price: priceFormat(
                  intl,
                  calculateTotalPriceByType(type, plan, settingsState)
                ),
              }
            )}
            description={formatMessage({ id: el.descriptionId })}
            advantages={formatMessage({ id: el.advantagesId }).split("\n")}
            buttonText={formatMessage({ id: el.buttonTextId })}
            onClick={() => {
              plan.changeBomType(type);
              if (plan.couponType == null && uiState.user.isFivestar) {
                plan.changeCouponType(COUPON_TYPES.Fivestar);
              }
              uiState.savePlan();
              uiState.sendMarketingStatistics();
            }}
            blurred={plan.bomType != null && type !== plan.bomType}
          />
        ))}
      </div>
      {planBomType != null && planCoupon == null && (
        <CouponSelection
          price={calculateTotalPriceByType(planBomType, plan, settingsState)}
          close={
            uiState.user.isAdmin
              ? uiState.hideCouponTypeSelection
              : undefined
          }
          onChange={async (type, bomItem) => {
            if (uiState.user.isAdmin) {
              plan.changeCouponType(type);
              uiState.savePlan();
            } else {
              const url = await uiState.couponCheckout(type, bomItem);
              if (typeof url === "string") redirectTo(url);
            }
          }}
          priceFormat={priceFormat}
        />
      )}
    </WindowPopup>
  );
};
BomType = observer(BomType);

export default BomType;
