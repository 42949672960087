import * as React from "react";
import * as MobxReact from "mobx-react";
import BomType from "./BomType";

import BomList from "./BomList";
import useStores from "../../hooks/useStores";
import { availableStatuses } from "../../store/planFactory/injectPlanSerialization";

let BomPage = () => {
  const { uiState } = useStores();
  const { step, plan, user, canFetchPrice, fetchPrices } = uiState;

  const isBomPage = step.name === "recommendations";
  React.useEffect(() => {
    if (isBomPage && canFetchPrice) fetchPrices();
  }, [isBomPage, canFetchPrice, fetchPrices]);

  if (plan == null) return null;

  const planStatus = plan.status;
  const isCheckStatus = planStatus === availableStatuses.ToCheck
    || planStatus === availableStatuses.DuplicateToCheck;
  const isFinalize = planStatus === availableStatuses.Finalize;

  if (!isBomPage) return null;
  if (canFetchPrice === true) return null;
  if (uiState.calculatingTitle != null) return null;
  if (user.isCustomer && (isCheckStatus || isFinalize)) return null;

  if (plan.bomType == null) return <BomType />;

  if (!user.isGala && !plan.couponType && uiState.couponTypeVisible) {
    return <BomType />
  } else {
    return <BomList />
  }
};
BomPage = MobxReact.observer(BomPage);

export default BomPage;
