import * as React from "react";
import { useIntl } from "react-intl";
import Button from "../elements/Button";

import { COUPON_TYPES, COUPON_DISCOUNTS } from "../../config/dictionary";

import { ReactComponent as BasicSVG } from "../../assets/gruppe-2962.svg";
import { ReactComponent as ComfortSVG } from "../../assets/gruppe-2964.svg";
import { ReactComponent as Star5SVG } from "../../assets/gruppe-2969.svg";
import { ReactComponent as InformIcon } from "../../assets/gruppe-inform.svg";
import useStores from "../../hooks/useStores";

const COUPON_ICONS = {
  [COUPON_TYPES.Basic]: BasicSVG,
  [COUPON_TYPES.Comfort]: ComfortSVG,
  [COUPON_TYPES.Fivestar]: Star5SVG,
};

// TODO: work on fonts
function Coupon({
  onClick,
  priceFormat,
  articleNO,
  bomPrice,
  price,
  Icon,
}) {
  const intl = useIntl();

  // TODO: this is how it should be done for all elements in bomList
  const title = `${articleNO}.name`;
  const videoLink = `${articleNO}.videoLink`;
  const description = `${articleNO}.description`;

  const { formatMessage } = intl;
  return (
    <div className="coupon">
      <div className="coupon-image">
        <Icon />
        <div className="coupon-price-wrapper">
          <span className="coupon-price">{Math.round(price)}</span>
          <span className="coupon-currency">€</span>
        </div>
      </div>
      <div className="coupon-content">
        <div className="coupon-title">
          <span>{formatMessage({ id: title })}</span>
          <a
            href={formatMessage({ id: videoLink })}
            target="_blank"
            rel="noreferrer"
          >
            <InformIcon />
          </a>
        </div>
        <div className="coupon-descr">
          {formatMessage({ id: description })}
        </div>
        <div className="bom-price">
          <span>
            {formatMessage({
              id: "texts.steps.bomType.couponType.bomPrice",
            })}
          </span>
          <span>
            € {priceFormat(intl, bomPrice)}
          </span>
        </div>
        <div className="submit">
          <Button
            title={formatMessage({
              id: "texts.steps.bomType.couponType.selectButton",
            })}
            onClick={onClick}
          />
        </div>
      </div>
    </div>
  );
}

function CouponSelection({ price, onChange, close, priceFormat }) {
  const { formatMessage } = useIntl();
  const { uiState } = useStores();
  const bomItems = uiState.plan.bomItems;

  // resolve bom items (along with prices)
  const couponItems = React.useMemo(() => {
    let result = new Map();
    for (const e of bomItems) {
      if (e.bomId === "coupon-basic") {
        result.set(COUPON_TYPES.Basic, e);
      } else if (e.bomId === "coupon-comfort") {
        result.set(COUPON_TYPES.Comfort, e);
      } else if (e.bomId === "coupon-fivestar") {
        result.set(COUPON_TYPES.Fivestar, e);
      }

      if (result.size === 3) break;
    }
    return result;
  }, [bomItems]);

  if (couponItems.length < 3) return null;

  return (
    <>
      <div className="coupon-popup-spacer" />
      <div className="window-popup coupon-popup scroll">
        <div className="window-popup-container">
          <div className="window-popup-header">
            <span style={{ fontWeight: "bold", marginRight: 10 }}>
              {formatMessage({
                id: "texts.steps.bomType.couponType.title",
              })}
            </span>
            <span>
              {formatMessage({
                id: "texts.steps.bomType.couponType.subtitle",
              })}
            </span>
            {close != null && (
              <div className="coupon-close" onClick={close}>
                {formatMessage({
                  id: "texts.steps.bomType.couponType.admin.close"
                })}
              </div>
            )}
          </div>
          <div className="coupon-popup-content">
            {Object.values(COUPON_TYPES).map((type) => {
              const discount = COUPON_DISCOUNTS[type];
              const item = couponItems.get(type);
              return (
                <Coupon
                  key={type}
                  onClick={() => onChange(type, item)}
                  priceFormat={priceFormat}
                  articleNO={item.articleNO}
                  bomPrice={discount(price)}
                  price={item.pricePerUnit}
                  Icon={COUPON_ICONS[type]}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default CouponSelection;
