export enum IRRIGATION_TUBE_TYPE {
  "BluLock24mm" = "bluLock_24mm",
  "PE_25mm" = "pe_25mm",
  "PE_32mm" = "pe_32mm",
}

export enum PRESSURE_TUBE_TYPE {
  "PE_25mm" = "pe_25mm",
  "PE_32mm" = "pe_32mm",
}

export enum QUALITY_TYPES {
  Eco = "eco",
  EcoHT = "eco HT",
  EcoRB = "eco RB",
  ExpHT = "exp HT",
  ExpRB = "exp RB",
  Expert = "expert",
  PremHT = "prem HT",
  PremRB = "prem RB",
  Premium = "premium",
}

export enum NOZZLE_TYPES {
  Prs30 = "PRS30",
  Prs40 = "PRS40",
  ZsGr24 = "ZS-GR24",
}

export enum COUPON_TYPES {
  Basic = "basic",
  Comfort = "comfort",
  Fivestar = "fivestar",
}

export const COUPON_DISCOUNTS = {
  [COUPON_TYPES.Basic]: (price: number) => price - (price > 1000 ? 50 : 0),
  [COUPON_TYPES.Comfort]: (price: number) => 0.9*price,
  [COUPON_TYPES.Fivestar]: (price: number) => 0.9*price,
}

