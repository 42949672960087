import { sleep } from "@dvsproj/ipat-core/helpers";
import { sizeInMetersByPixel } from "@dvsproj/ipat-core/planUtils";
import { trenchingPipelines } from "@dvsproj/ipat-core/trenchingUtils";
import { throttle } from "lodash";
import { action } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import useStores from "../../hooks/useStores";
import bomItemFactory from "../../store/types/bomItemFactory";
import trenchFactory from "../../store/types/trenchFactory";
import { urlDecorator } from "../../utils/api";
import {
  bomHasOutdatedElements,
  calculateTotalPrice,
  convertBomListByBoxes,
  groupBomByBomOrder,
} from "../../utils/bomUtils";
import { PartnersCsvExport } from "../../utils/csv/partners-csv-export";
import { convertBlobToBase64 } from "../../utils/uiUtils";
import BomFooter from "../bom/BomFooter";
import BomHeader from "../bom/BomHeader";
import BomNavBar from "../bom/BomNavBar";
import Link, { downloadFileFromBase64, redirectTo } from "../elements/Link";
import Scroll from "../elements/Scroll";
import WindowPopup from "../popups/WindowPopup";
import { availableStatuses } from "./../../store/planFactory/injectPlanSerialization";
import GroupBomList from "./GroupBomList";

import { ReactComponent as CloseDisabledSVG } from "../../assets/close-disabled.svg";
import { ReactComponent as CloseSVG } from "../../assets/close.svg";
import { ReactComponent as BigDvsCheckSVG } from "./../../assets/big-dvs-check.svg";
import { ReactComponent as DownloadPdfSVG } from "./../../assets/download_pdf.svg";

function BomFilter({ filterValue, changeFilterValue }) {
  const { formatMessage } = useIntl();
  return (
    <div className="bom-filter">
      <span className="label">
        {formatMessage({ id: "texts.steps.bom.popup.filter.title" })}
      </span>
      <input
        type="text"
        value={filterValue}
        onChange={(e) => changeFilterValue(e.target.value)}
        maxLength={128}
      />
      <span
        className={`reset-button ${!filterValue ? "disabled" : ""}`}
        onClick={(e) => {
          changeFilterValue("");
        }}
      >
        {!filterValue ? <CloseDisabledSVG /> : <CloseSVG />}
      </span>
    </div>
  );
}

const BomPopup = observer(
  ({
    user,
    totalPrice,
    groupBomList,
    planToFinalize,
    labels,
    dvsCheckButtonLabel,
    planIsEditable,
    onBomItemChange = () => {},
    planToCheck = () => {},
    redirectWishList = () => {},
    generatePdf = () => {},
    buyOnlySomeProducts = () => {},
    projectCalculation = () => {},
    requestRainBirdProducts = () => {},
    duplicatePlan = () => {},
    requestInstallation = () => {},
    minInstallationTime,
    maxInstallationTime,
    buyIsDisabled,
    status,
    showCustomerPrice,
    setShowCustomerPrice,
    customerPriceMultiplier,
  }) => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const [hasShowOthers, showOthers] = React.useState(false);

    const isCheckStatus =
      [availableStatuses.ToCheck, availableStatuses.DuplicateToCheck].indexOf(
        status
      ) >= 0;

    const isFinalize = status === availableStatuses.Finalize;

    const [alternativeBomGroups, setAlternativeBomGroups] =
      React.useState(groupBomList);

    const [filterValue, setFilterValue] = React.useState("");

    const applyFilter = React.useCallback(
      (query) => {
        setAlternativeBomGroups(
          groupBomList.map((e) => {
            return {
              ...e,
              childs: e.childs.filter((item) => {
                query = query.toLowerCase();
                if (item.articleNO?.toLowerCase().includes(query)) return true;

                if (
                  item.name != null &&
                  !item.hidden &&
                  formatMessage({ id: item.name }).toLowerCase().includes(query)
                ) {
                  return true;
                }

                return (
                  item.description != null &&
                  formatMessage({ id: item.description })
                    .toLowerCase()
                    .includes(query)
                );
              }),
            };
          })
        );
      },
      [formatMessage, groupBomList]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const applyFilterDebounced = React.useCallback(
      throttle(applyFilter, 200, { leading: false, trailing: true }),
      [applyFilter]
    );

    const changeFilterValue = (v) => {
      setFilterValue(v);
      applyFilterDebounced(v);
    };

    const bomGroups = !hasShowOthers
      ? groupBomList.map((e) => {
          return {
            ...e,
            childs: e.childs.filter(
              ({ quantity, defaultQuantity }) =>
                quantity > 0 || defaultQuantity > 0
            ),
          };
        })
      : alternativeBomGroups;

    return (
      <WindowPopup
        wrapperClassName="recom-wrapper"
        title={
          <>
            <BomHeader labels={labels} />
            <BomNavBar
              value={hasShowOthers}
              onClick={showOthers}
              options={[
                {
                  title: formatMessage({ id: labels.showShort }),
                  value: false,
                },
                planIsEditable
                  ? {
                      title: formatMessage({ id: labels.showLong }),
                      value: true,
                    }
                  : undefined,
              ].filter((e) => e)}
            />
          </>
        }
        footer={
          <BomFooter
            user={user}
            planIsEditable={planIsEditable}
            generatePdf={generatePdf}
            dvsCheckButtonLabel={dvsCheckButtonLabel}
            planToCheck={isCheckStatus || isFinalize ? undefined : planToCheck}
            redirectWishList={redirectWishList}
            planToFinalize={planToFinalize}
            totalPrice={totalPrice}
            projectCalculation={projectCalculation}
            requestRainBirdProducts={requestRainBirdProducts}
            duplicatePlan={duplicatePlan}
            requestInstallation={requestInstallation}
            minInstallationTime={minInstallationTime}
            maxInstallationTime={maxInstallationTime}
            buyIsDisabled={buyIsDisabled}
            showCustomerPrice={showCustomerPrice}
            setShowCustomerPrice={setShowCustomerPrice}
          />
        }
        className="recom"
      >
        <Scroll className="recom-content">
          <GroupBomList
            groupBomList={bomGroups.filter(({ childs }) => childs.length > 0)}
            planIsEditable={planIsEditable}
            onBomItemChange={onBomItemChange}
            buyOnlySomeProducts={buyOnlySomeProducts}
            showCustomerPrice={showCustomerPrice}
            customerPriceMultiplier={customerPriceMultiplier}
          />
        </Scroll>
        {hasShowOthers ? (
          <BomFilter
            filterValue={filterValue}
            changeFilterValue={changeFilterValue}
          />
        ) : null}
      </WindowPopup>
    );
  }
);

let BomList = () => {
  const { uiState } = useStores();
  const intl = useIntl();
  const { formatMessage } = intl;

  const { settingsState } = uiState;
  const { bomItems: planBomItems, background } = uiState.plan;

  const getPlanJSON = React.useCallback(
    (plan) => {
      let planJSON = plan.toJSON;
      if (planJSON && background) {
        const { width, height, src } = background;
        planJSON.background = {
          width,
          height,
          src,
        };
      }
      return planJSON;
    },
    [background]
  );

  const groupBomList = groupBomByBomOrder(
    planBomItems.filter((e) => e.name != null),
    settingsState
  );

  const buyIsDisabled = bomHasOutdatedElements(planBomItems, settingsState);

  const bomLabels = settingsState ? settingsState.texts.steps.bom.popup : null;

  const dvsCheckButtonLabel = formatMessage({ id: bomLabels.dvsCheck });

  const bomListWithBoxes = convertBomListByBoxes(
    uiState.settingsState,
    uiState.plan.bomItems ?? []
  )
    .map((e) => bomItemFactory(e, uiState.plan.pricesDictionary))
    .filter(({ name, quantity }) => name != null && quantity > 0);

  let minInstallationTime = 0;
  let maxInstallationTime = 0;
  for (const product of bomListWithBoxes) {
    minInstallationTime +=
      product.quantity * (product.minInstallationTime ?? 0);
    maxInstallationTime +=
      product.quantity * (product.maxInstallationTime ?? 0);
  }

  const [showCustomerPrice, setShowCustomerPrice] = React.useState(false);
  const customerPriceMultiplier = showCustomerPrice ? 1.25 : 1;

  return (
    <BomPopup
      user={uiState.user}
      planToFinalize={() => {
        const confirmLabels = uiState.settingsState.dialog;
        uiState.showConfirm({
          title: formatMessage({ id: confirmLabels.finalizeTitle }),
          description: formatMessage({ id: confirmLabels.finalizeText }),
          confirm: async () => {
            try {
              const planJSON = getPlanJSON(uiState.plan);
              uiState.calcApi.generatePdf(
                uiState.planId,
                planJSON,
                intl.locale,
                "redirectToCart"
              );
              const url = await uiState.generateCartURL(planBomItems);

              // IPAT-411 remove the plan locking after purchase
              // uiState.plan.status = "finalize";
              // await uiState.savePlan();

              if (url) redirectTo(url);
            } catch (e) {}
          },
        });
      }}
      buyOnlySomeProducts={async (bomItems) => {
        try {
          const planJSON = getPlanJSON(uiState.plan);
          uiState.calcApi.generatePdf(
            uiState.planId,
            planJSON,
            intl.locale,
            "redirectToCart"
          );
          const url = await uiState.generateCartURL(bomItems);

          // IPAT-403, finalization is disabled for this operation based on a comment by Andreas from 20.03.2023
          // uiState.plan.status = "finalize";
          // await uiState.savePlan();

          if (url) redirectTo(url);
        } catch (e) {}
      }}
      planToCheck={() => {
        const confirmLabels = uiState.settingsState.dialog.toCheck;
        uiState.showConfirm({
          title: formatMessage({ id: confirmLabels.title }),
          description: formatMessage({ id: confirmLabels.text }),
          icon: <BigDvsCheckSVG />,
          confirm: async () => {
            try {
              const url = await uiState.planToCheck();
              if (url) redirectTo(url);
            } catch (e) {
              console.error(e);
            }
          },
        });
      }}
      redirectWishList={async () => {
        const planJSON = getPlanJSON(uiState.plan);
        uiState.calcApi.generatePdf(
          uiState.planId,
          planJSON,
          intl.locale,
          "redirectToCart"
        );
        const url = await uiState.generateWishListURL(intl.locale);
        if (url) redirectTo(url);
      }}
      generatePdf={async (e) => {
        action(() => {
          uiState.calculatingTitle =
            uiState.settingsState.texts.steps.bom.popup.pdfGenerationTitle;
        })();
        await sleep(0);

        try {
          const pdfName = formatMessage({
            id: bomLabels.pdfFile.filename,
          }).replace("{planName}", uiState.planName);

          const planJSON = getPlanJSON(uiState.plan);
          const { url, blob } = await uiState.calcApi.generatePdf(
            uiState.planId,
            planJSON,
            intl.locale,
            "manual"
          );

          if (url != null && blob != null) {
            const confirmLabels = uiState.settingsState.dialog.downloadPdf;
            const pdfURL = urlDecorator(url).replace("{pdfName}", pdfName);

            const Description = () => (
              <FormattedMessage
                id={confirmLabels.text}
                values={{
                  link: (
                    <Link
                      href={pdfURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {pdfURL}
                    </Link>
                  ),
                }}
              />
            );

            uiState.showConfirm({
              icon: <DownloadPdfSVG />,
              title: formatMessage({ id: confirmLabels.title }),
              description: <Description />,
              confirmLabel: formatMessage({ id: confirmLabels.confirm }),
              confirm: async () => {
                // TODO: check if await is required
                await downloadFileFromBase64(
                  "data:application/pdf;base64," + blob,
                  pdfName + ".pdf"
                );
              },
            });
          }
        } catch (err) {
          console.error(err);
        }
        action(() => {
          uiState.calculatingTitle = null;
        })();
      }}
      planIsEditable={uiState.planIsEditable}
      groupBomList={groupBomList}
      totalPrice={
        showCustomerPrice
          ? calculateTotalPrice(planBomItems) * customerPriceMultiplier
          : calculateTotalPrice(planBomItems)
      }
      labels={bomLabels}
      dvsCheckButtonLabel={dvsCheckButtonLabel}
      status={uiState.plan ? uiState.plan.status : null}
      onBomItemChange={
        uiState.reactions ? uiState.reactions.onBomItemChange : () => {}
      }
      projectCalculation={async () => {
        const filename =
          formatMessage(
            {
              id: "texts.steps.bom.popup.csvFile.fileName",
            },
            { planName: uiState.planName }
          ) + ".csv";

        const trenches = trenchingPipelines(
          [
            ...uiState.plan.pipelines,
            ...uiState.plan.irrigationValveCable,
            ...uiState.plan.waterMeterCable,
          ],
          [],
          10
        ).map((t) => trenchFactory(t));

        const trenchesLength = sizeInMetersByPixel(
          trenches.reduce((acc, c) => acc + c.length, 0),
          uiState.plan.scale
        );

        //IPAT-515 actualize user info before generate CSV
        await uiState.user.fetch();

        const csvExport = new PartnersCsvExport(
          intl,
          bomListWithBoxes,
          trenchesLength,
          uiState.user.surcharges
        );

        csvExport.generate();
        const blob = csvExport.getToBlob();

        downloadFileFromBase64(await convertBlobToBase64(blob), filename);
      }}
      requestRainBirdProducts={async () => {
        action(() => {
          uiState.calculatingTitle = "texts.steps.bom.popup.rainBird";
        })();
        await sleep(0);

        try {
          await uiState.calcApi.requestRainBirdProducts(
            uiState.planId,
            uiState.user.email
          );
          action(() => {
            uiState.calculatingTitle = null;
          })();

          await uiState.showAlert({
            title: formatMessage({ id: "texts.steps.bom.popup.rainBird" }),
            description: formatMessage({
              id: "texts.steps.bom.popup.rainBird.success",
            }),
          });
        } catch (e) {
          action(() => {
            uiState.calculatingTitle = null;
          })();
          console.error("Error in requestRainBirdProducts", e);
          uiState.showRestError("requestRainBirdProducts", e);
        }
      }}
      duplicatePlan={async () => {
        await uiState.duplicatePlan();
      }}
      requestInstallation={async () => {
        action(() => {
          uiState.calculatingTitle = "texts.steps.bom.popup.installer";
        })();
        await sleep(0);

        try {
          await uiState.calcApi.requestInstaller(
            uiState.planId,
            uiState.user.email,
            uiState.user.zip,
            uiState.user.city,
            uiState.user.country
          );
          action(() => {
            uiState.calculatingTitle = null;
          })();

          await uiState.showAlert({
            title: formatMessage({ id: "texts.steps.bom.popup.installer" }),
            description: formatMessage({
              id: "texts.steps.bom.popup.installer.success",
            }),
          });
        } catch (e) {
          action(() => {
            uiState.calculatingTitle = null;
          })();
          console.error("Error in requestInstallation", e);
          uiState.showRestError("requestInstallation", e);
        }
      }}
      minInstallationTime={minInstallationTime}
      maxInstallationTime={maxInstallationTime}
      buyIsDisabled={buyIsDisabled}
      showCustomerPrice={showCustomerPrice}
      setShowCustomerPrice={setShowCustomerPrice}
      customerPriceMultiplier={customerPriceMultiplier}
    />
  );
};

BomList = observer(BomList);

export default BomList;
