import { useIntl } from "react-intl";
import { observer } from "mobx-react-lite";

import { urlDecorator } from "../../utils/api";

import { availableStatuses } from "../../store/planFactory/injectPlanSerialization";

import Steps from "./steps/Steps";
import Logo from "./Logo";

import DownloadPlanButton from "./DownloadPlanButton";
import HeaderButton from "./HeaderButton";
import ImageButton from "./ImageButton";
import DownloadCsvButton from "./DownloadCsvButton";
import LockButton from "./LockButton";
import SavingInfo from "./SavingInfo";

import { ReactComponent as AvatarSVG } from "../../assets/avatar.svg";
import { ReactComponent as CheckedSVG } from "../../assets/checked.svg";
import { ReactComponent as TourSVG } from "../../assets/tour-button-icon.svg";
import { ReactComponent as HelperSVG } from "../../assets/helper.svg";
import { ReactComponent as SmartGardenSVG } from "../../assets/smart-garden.svg";

import useStores from "../../hooks/useStores";
import { useTourContext } from "../tour/TourContext";

let Header = () => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const { uiState } = useStores();

  const {
    user,
    plan,
    stepIdx: currentStep,
    hasDev,
    settingsState,
    stepIdxByName,
  } = uiState;

  const ctx = useTourContext();

  const labels = settingsState.texts.header;

  return (
    <header>
      <Logo
        planName={uiState.planName}
        setPlanName={plan != null ? uiState.setPlanName : undefined}
        updatePlan={uiState.updatePlan}
        formatMessage={formatMessage}
        showPhoneNumber={currentStep >= 2}
      />
      <Steps />
      <div className="right">
        {hasDev && (
          <ImageButton
            planId={uiState.planId}
            plan={plan}
            currentStep={currentStep}
            calcApi={uiState.calcApi}
          />
        )}

        {hasDev && <DownloadPlanButton plan={plan} />}

        {user.isAdmin && currentStep === stepIdxByName("recommendations") && (
          <DownloadCsvButton
            plan={plan}
            planName={uiState.planName}
            settingsState={settingsState}
            pricesDictionary={plan.pricesDictionary}
          />
        )}

        {ctx.tourCanBeShown && (
          <HeaderButton
            onClick={() => {
              uiState.clearSelectedElement();
              ctx.showTour();
            }}
            Icon={TourSVG}
            label={formatMessage({ id: "tour.showTour.label" })}
          />
        )}

        {(availableStatuses.ToCheck === plan?.status ||
          availableStatuses.DuplicateToCheck === plan?.status) &&
          user.isAdmin &&
          currentStep === stepIdxByName("recommendations") && (
            <HeaderButton
              onClick={uiState.planChecked}
              Icon={CheckedSVG}
              label={formatMessage({ id: labels.checkedLabel })}
            />
          )}

        {plan?.sensors.length > 0 && (
          <HeaderButton
            className="smart-garden"
            href={
              settingsState
                ? urlDecorator(
                    settingsState.links.smartGardenURL.replace(
                      ":planId",
                      uiState.planId
                    )
                  )
                : "/"
            }
            rel="noopener noreferrer"
            target="planer"
            noredirect="true"
            Icon={({ width, height }) => (
              <div className="icon" style={{ width, height }}>
                <SmartGardenSVG />
              </div>
            )}
            label={
              labels
                ? formatMessage({ id: labels.smartGardenLabel })
                : undefined
            }
          />
        )}

        {
          //IPAT-412 Implement manual locking against unintended modification
          plan && (
            <LockButton
              isLocked={!plan?.editable}
              lock={async () => {
                plan.lock();
                await uiState.updatePlan();
              }}
              unlock={async () => {
                plan.unlock();
                await uiState.updatePlan();
              }}
              labels={labels}
            />
          )
        }

        <HeaderButton
          href={
            settingsState
              ? urlDecorator(
                  formatMessage({ id: settingsState.links.helperURL })
                )
              : "/"
          }
          Icon={HelperSVG}
          label={labels ? formatMessage({ id: labels.helperLabel }) : undefined}
          target="_blank"
          rel="noopener noreferrer"
        />

        <SavingInfo
          labels={labels}
          lastSaveDate={uiState.lastSaveDate}
          saveStatus={uiState.saveStatus}
        />

        <HeaderButton
          className="user"
          href={
            settingsState ? urlDecorator(settingsState.links.logoutURL) : "/"
          }
          Icon={AvatarSVG}
          label={user?.name}
        />
      </div>
    </header>
  );
};
Header = observer(Header);

export default Header;
